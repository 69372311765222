import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withViewTransitions,
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  withInMemoryScrolling,
  withComponentInputBinding
} from '@angular/router';

import { routes } from './app.routes';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from '@core/core.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LOADER_CONFIG } from './layout/config/loaderConfiguration';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '@environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { StateModule } from '@state/state.module';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { ChoizMessagesComponent } from '@shared/components/choiz-messages/choiz-messages.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    MessageService,
    provideRouter(routes, inMemoryScrollingFeature, withViewTransitions(), withComponentInputBinding() ),
    importProvidersFrom(
      HttpClientModule,
      CommonModule,
      BrowserAnimationsModule,
      CoreModule,
      NgxUiLoaderModule.forRoot(LOADER_CONFIG),
      SocketIoModule.forRoot( environment.socketConfig ),
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      ToastModule,
      NgxsModule.forRoot([], {
        developmentMode: !environment.production,
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'es',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory, //translateBrowserLoaderFactory,
          deps: [HttpClient]
        }
    }),
    StateModule,
    MessageService,
    )
  ],

};
