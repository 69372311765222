import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CleanFiltersOrdersAction, CleanFiltersRequestsAction } from '@state/index.actions';
import { Store } from '@ngxs/store';
//import { CleanFiltersRequestsAction } from '@state/index.actions';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';

interface BreadCrumb {
  label:string
}

@Component({
  selector: 'choiz-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  navigation:string[];
  items: BreadCrumb[] = []

  constructor(
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.createBreadcrumb(this.router);
    this.router.events.subscribe((data:any)=> {
      data.url && this.createBreadcrumb(data)
    });
  }

  createBreadcrumb(router:Router){
    this.items = []
    this.navigation = router.url.split('/');
    this.navigation = router.url.replace('', '').split('/');

    this.navigation = this.navigation.filter(item => {
       return item ? item : null
    })

    this.navigation = this.navigation.map(item => {
      return item.replace('-', ' ').replace('-', ' ')
    })

    this.navigation.map((item, index) => {
      //////// Oculta labels que se decidio no mostrar //////////
      const labelsDontShow = {
        admin: true,
        ar: true,
        auth: true,
        pharmacy: true,
      }
      if(labelsDontShow[item]) {
        return
      }
      /////////////////////////////////////////////////////////

      // Cambia el ID del chat por el label "Mensajes"
      if(this.navigation[index -1] === 'chat'){
        item = "Mensajes"
      }

      const navigationItem = {
        label: this.createLabel(item),
      };
      this.items.push(navigationItem)
    })
  }

  createLabel(routeName:string){
    if(routeName.includes('?')){
      routeName = routeName.split('?')[0];
    }
    
    switch(routeName) {
      case 'requests': return 'Solicitudes';
      case 'followup': return 'Seguimiento';
      case 'orders': return 'Órdenes';
      case 'detail': return 'Detalle';
      case 'myaccount': return 'Mi cuenta';
      case 'clients': return 'Clientes';
      case 'abms': return 'Abms';
      case 'medicines': return 'Medicinas';
      default: return routeName.charAt(0).toUpperCase() + routeName.slice(1);
    }
  }

  handleClick({item}){
    const { label } = item;
    if(this.items.includes(item)){
      let arrIndex = this.items.indexOf(item);
      if (arrIndex !== -1) {
          this.items.splice(arrIndex, 1);
      }
    }
    switch(label.toLowerCase()){
        case 'dashboard': this.router.navigateByUrl('dashboard'); this.createBreadcrumb(this.router); break;
        case 'seguimiento': this.router.navigateByUrl('followup?status=all'); this.createBreadcrumb(this.router); break;
        case 'órdenes': this.store.dispatch(new CleanFiltersOrdersAction()); this.router.navigateByUrl('orders'); this.createBreadcrumb(this.router); break;
        case 'solicitudes': this.store.dispatch(new CleanFiltersRequestsAction()); this.router.navigateByUrl('requests'); this.createBreadcrumb(this.router); break;
        case 'chat': this.router.navigateByUrl('chat'); this.createBreadcrumb(this.router); break;
        case 'clientes': this.router.navigateByUrl('clients'); this.createBreadcrumb(this.router); break;
        case 'abms': this.router.navigateByUrl('abms'); this.createBreadcrumb(this.router); break;
        case 'medicinas': this.router.navigateByUrl('abms/medicines'); this.createBreadcrumb(this.router); break;
        default: this.createBreadcrumb(this.router); break;
    }
  }

}
