import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-topbar',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    public layoutService = inject(LayoutService); 
    public _bs = inject(BreakpointService);

    items!: MenuItem[];
    @Input() fill: boolean = false;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

}
