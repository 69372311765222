import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from 'ngx-ui-loader';

export const LOADER_CONFIG: NgxUiLoaderConfig  = {
  bgsColor: "#8243F2",
  bgsOpacity: 0.5,
  bgsPosition: POSITION.bottomRight,
  bgsSize: 60,
  bgsType: SPINNER.ballSpinClockwise,
  blur: 6,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#8243F2",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  fgsType: SPINNER.ballSpinClockwise,
  gap: 34,
  logoPosition: POSITION.centerCenter,
  logoSize: 120,
  //logoUrl: "assets/images/logo.svg",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(255, 255, 255, 0.7)",
  pbColor: "#8243F2",
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  hasProgressBar: false,
  text: "Cargando",
  textColor: "#8243F2",
  textPosition: POSITION.centerCenter,
  maxTime: -1,
  minTime: 1000
}
