import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';

export const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path:'', loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path:'abms', loadChildren: () => import('@abms/abms.module').then(m => m.AbmsModule) },
            { path:'dashboard', loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path:'chat', loadChildren: () => import('@chat/chat.module').then(m => m.ChatModule) },
            { path:'myaccount', loadChildren: () => import('@myaccount/myaccount.module').then(m => m.MyaccountModule) },
            { path:'clients', loadChildren: () => import('@clients/clients.module').then(m => m.ClientsModule) },
            { path:'requestsold', loadChildren: () => import('@requestsold/requests.module').then(m => m.RequestsModule) },
            { path:'requests', loadChildren: () => import('@requests/requests-new.module').then(m => m.RequestsNewModule) },
            { path:'orders', loadChildren: () => import('@orders/orders.module').then(m => m.OrdersModule) },
            { path:'followup', loadChildren: () => import('@followup/followup.module').then(m => m.FollowupModule) },
            //{ path: '', pathMatch: 'full', redirectTo: 'auth' }
        ]
    },
    { path:'auth', loadChildren: () => import('@shared/auth/auth.module').then(m => m.AuthModule) },
    { path: 'notfound', loadChildren: () => import('@shared/components/notfound/notfound.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: '/notfound' },
];
