import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";
import { AppSidebarComponent } from "./app.sidebar.component";
import { AppTopBarComponent } from './app.topbar.component';
import { ClientsDetailService } from '@clients/clients-detail.service';
import { OrdersDetailService } from '@orders/orders-detail.service';
import { RequestsDetailService } from '@requests/requests-detail.service';
import { AuthService } from '@shared/auth/services/auth.service';
import { ColumnsService } from '@core/services/columns.service';
import { Store } from '@ngxs/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BreakpointService } from '../core/services/breakpoint.service';
import { WebsocketService } from '@core/services/websocket.service';
import { AppConfigComponent } from './config/app.config.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderStatusService } from '@services/orders-status.service';
import { IGender, IOrderStatus, ISex } from '@core/interfaces';
import { AuxiliaresService } from '@core/services/auxiliares.service';


@Component({
    selector: 'app-layout',
    standalone: true,
    imports: [CommonModule, RouterModule, AppSidebarComponent, AppConfigComponent, BreadcrumbComponent, AppTopBarComponent],
    templateUrl: './app.layout.component.html',
    providers: [DialogService]
})
export class AppLayoutComponent implements OnInit, OnDestroy {

    public layoutService = inject(LayoutService);
    public renderer = inject(Renderer2);
    public router = inject(Router);
    private _cs = inject(ClientsDetailService);
    private _os = inject(OrdersDetailService);
    private _rs = inject(RequestsDetailService);
    private _auth = inject(AuthService);
    private cols = inject(ColumnsService);
    private store = inject(Store);
    private _loader = inject(NgxUiLoaderService);
    public _bs = inject(BreakpointService);
    //public _ws = inject(WebsocketService);
    public _ordersStatus = inject(OrderStatusService);
    public _auxiliares = inject(AuxiliaresService);

    showId: boolean = false;
    overlayMenuOpenSubscription: Subscription;

    private subscriptions: Subscription = new Subscription;
    private unsubscribe$ = new Subject<void>();

    menuOutsideClickListener: any;

    profileMenuOutsideClickListener: any;

    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

    constructor() {
        this._loader.start();
        this.getDefinitions();
        this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
            if (!this.menuOutsideClickListener) {
                this.menuOutsideClickListener = this.renderer.listen('document', 'click', event => {
                    const isOutsideClicked = !(this.appSidebar.el.nativeElement.isSameNode(event.target) || this.appSidebar.el.nativeElement.contains(event.target)
                        || this.appTopbar.menuButton.nativeElement.isSameNode(event.target) || this.appTopbar.menuButton.nativeElement.contains(event.target));

                    if (isOutsideClicked) {
                        this.hideMenu();
                    }
                });
            }

            if (!this.profileMenuOutsideClickListener) {
                this.profileMenuOutsideClickListener = this.renderer.listen('document', 'click', event => {
                    const isOutsideClicked = !(this.appTopbar.menu.nativeElement.isSameNode(event.target) || this.appTopbar.menu.nativeElement.contains(event.target)
                        || this.appTopbar.topbarMenuButton.nativeElement.isSameNode(event.target) || this.appTopbar.topbarMenuButton.nativeElement.contains(event.target));

                    if (isOutsideClicked) {
                        this.hideProfileMenu();
                    }
                });
            }

            if (this.layoutService.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            }
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.hideMenu();
                this.hideProfileMenu();
            });
    }

    async ngOnInit() {
        const isLogged = await this._auth.isLoggedIn();
        if(isLogged){
            // this._ws.loginWS(this._auth.user.fullName, this._auth.user.token).then(() => {
            // })
             this.cols.loadColumnsState().then(state => {
                 if(state){
                     this._loader.stop();
                 }
             })
            this.getData();
        } else {
            this.router.navigateByUrl('auth')
        }
    }

    hideMenu() {
        this.layoutService.state.overlayMenuActive = false;
        this.layoutService.state.staticMenuMobileActive = false;
        this.layoutService.state.menuHoverActive = false;
        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
            this.menuOutsideClickListener = null;
        }
        this.unblockBodyScroll();
    }

    hideProfileMenu() {
        this.layoutService.state.profileSidebarVisible = false;
        if (this.profileMenuOutsideClickListener) {
            this.profileMenuOutsideClickListener();
            this.profileMenuOutsideClickListener = null;
        }
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    get containerClass() {
        return {
            'layout-theme-light': this.layoutService.config.colorScheme === 'light',
            'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple
        }
    }

    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
        this.subscriptions.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getDefinitions(){
        this._cs.getJsonDefinition();
        this._os.getJsonDefinition();
        this._rs.getJsonDefinition();
    }

    getData(){
        this._auxiliares.getSex().pipe(takeUntil(this.unsubscribe$)).subscribe()
        this._auxiliares.getGender().pipe(takeUntil(this.unsubscribe$)).subscribe()
        this._auxiliares.getTreatments().pipe(takeUntil(this.unsubscribe$)).subscribe()
        this._auth.getUserRole();
        //this._ordersStatus.getOrderStates()
        this._ordersStatus.getOrderStates().pipe(takeUntil(this.unsubscribe$)).subscribe((status: IOrderStatus[]) => {
            this._ordersStatus.states.set(status)
        })
    }

}
