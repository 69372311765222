import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@shared/auth/services/auth.service';
import { LayoutService } from './service/app.layout.service';
import { FollowupService } from '@followup/followup.service';
import { Subject, Subscription } from 'rxjs';
import { AppMenuitemComponent } from './app.menuitem.component';
import { RequestsService } from '@requests/requests.service';

@Component({
    selector: 'app-menu',
    standalone: true,
    imports: [AppMenuitemComponent],
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    model2: any[] = [];

    menuTranslate: any;
    tags = [];
    tagsRequests = [];

    private subscriptions: Subscription = new Subscription;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        public layoutService: LayoutService,
        private ts: TranslateService,
        private _auth: AuthService,
        private _follow: FollowupService,
        private _requests: RequestsService,
    ) { }

    ngOnInit() {
        if(this._auth.isAdmin() || this._auth.isDoctor()){
            this.subscriptions.add(
                this._follow.getTags().subscribe((tags: any) => {
                    this.tags = tags;
                })
            );
        }
        if(this._auth.isAdmin() || this._auth.isDoctor()){
            this.subscriptions.add(
                this._requests.getTags().subscribe((tags: any) => {
                    this.tagsRequests = tags;
                })
            );
        }
        setTimeout(() => {
            this.translateMenu();
        }, 1000)
    }

    ngOnDestroy(){
        this.subscriptions.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeSession(){
        this.router.navigate(['/auth/entrar'])
    }

    translateMenu(){
        this.ts.get('MENU').subscribe(menu => {
            this.menuTranslate = menu;
            this.createMenu();
        });

    }

    menuDinamyc(section: string){
        let menu = [];
        if(section == 'followup'){
          this.tags.map(item => {
              let itemMenu = { label: item.label, icon: `${item.status}.png`, routerLink: '/followup', queryParams: {status: item.status.toLowerCase()} };
              menu.push(itemMenu);
          })
          menu.unshift( {label: 'Todos', icon: 'icon_all_menu.svg', routerLink: '/followup', queryParams: {status: 'all'}} )
        }
        if(section == 'requests'){
          this.tagsRequests.map(item => {
              let itemMenu = { label: item.label, icon: item.icon.length > 0 ? item.icon : `${item.status}.png`, routerLink: '/requests', queryParams: {status: item.status.toLowerCase(), refresh: true} };
              menu.push(itemMenu);
          })
          menu.unshift( {label: 'Todos', icon: 'icon_all_menu.svg', routerLink: '/requests', queryParams: {status: 'all', refresh: true}} )
        }
        return menu;
    }

    createMenu(){
        this.model2 = [
            {
                label: '',
                items:[
                    { label: this._auth.user ? this._auth.user.alias : '', icon: 'icon_user_small.svg', routerLink: '/myaccount' }
                ]
            },
            {
                label: '',
                items:[
                    { label: this.menuTranslate.logout, icon: 'icon_logout.svg', command: () => {
                      this.closeSession()
                    } }
                ]
            },
        ];

        this.model = [
            {
                label: '',
                items:[
                    { label: this.menuTranslate.dashboard, icon: 'icon_dashboard.svg', routerLink: '/dashboard', visible: this._auth.isAdmin() }
                ]
            },
            // {
            //     label: '',
            //     visible: this._auth.isAdmin() || this._auth.isDoctor(),
            //     items:[
            //         { label: this.menuTranslate.requests, icon: 'icon_requests.svg', routerLink: '/requests', queryParams: { status: 'all'}, visible: this._auth.isAdmin() || this._auth.isDoctor(), }
            //     ]
            // },
            {
              label: '',
              visible: this._auth.isAdmin() || this._auth.isDoctor(),
              items: [
                  {
                      label: this.menuTranslate.requests, icon: 'icon_requests.svg',
                      visible: this._auth.isAdmin() || this._auth.isDoctor(),
                      items: this.menuDinamyc('requests'),
                  },
              ]
            },
            {
                label: '',
                visible: this._auth.isAdmin() || this._auth.isPharma() || this._auth.isDoctor(),
                items: [
                    {
                        label: this.menuTranslate.followup, icon: 'icon_users.svg',
                        visible: this._auth.isAdmin() || this._auth.isDoctor(),
                        items: this.menuDinamyc('followup'),
                    },
                ]
            },
            {
                label: '',
                visible: this._auth.isAdmin() || this._auth.isPharma() || this._auth.isDoctor(),
                items: [
                    {
                        label: this.menuTranslate.orders, icon: 'icon_orders.svg',
                        visible: this._auth.isAdmin() || this._auth.isPharma() || this._auth.isDoctor(),
                        items: [
                            { label: this.menuTranslate.orders_all, icon: 'icon_all_menu.svg', routerLink: '/orders' },
                            { label: this.menuTranslate.orders_WAITING_FOR_PAYMENT, icon: 'icon_entregada_menu.svg', routerLink: '/orders', visible: this._auth.isAdmin(), queryParams: { status: '3', refresh: true } },
                            { label: this.menuTranslate.orders_pack_off, icon: 'icon_despachar_menu.svg', routerLink: '/orders', queryParams: { status: '9', refresh: true } },
                            { label: this.menuTranslate.orders_sent, icon: 'icon_enviadas_menu.svg', routerLink: '/orders', queryParams: { status: '11', refresh: true } },
                            { label: this.menuTranslate.orders_delivered, icon: 'icon_entregada_menu.svg', routerLink: '/orders', queryParams: { status: '10', refresh: true } },
                            { label: this.menuTranslate.orders_problem, icon: 'icon_problem_menu.svg', routerLink: '/orders', queryParams: { status: '8', refresh: true } },
                            { label: this.menuTranslate.orders_deleted, icon: 'icon_eliminadas.svg', routerLink: '/orders', visible: this._auth.isAdmin(), queryParams: { status: '5', refresh: true } },
                        ]
                    },
                ]
            },
            {
                label: '',
                visible: this._auth.isAdmin() || this._auth.isDoctor(),
                items:[
                    { label: this.menuTranslate.users, icon: 'icon_users.svg', routerLink: '/clients',  visible: this._auth.isAdmin() || this._auth.isDoctor(), }
                ]
            },
            // {
            //     label: '',
            //     visible: this._auth.isAdmin() || this._auth.isDoctor(),
            //     items:[
            //         { label: this.menuTranslate.chat, icon: 'icon_chat.svg', routerLink: ['/chat'], visible: this._auth.isAdmin() || this._auth.isDoctor(), },
            //     ]
            // },
            // {
            //     label: '',
            //     visible: this._auth.isAdmin(),
            //     items: [
            //         { label: this.menuTranslate.abms, icon: 'icon_plus.svg', routerLink: ['/abms'],  visible: this._auth.isAdmin(), },
            //     ]
            // },

        ];
    }


}
