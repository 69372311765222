<button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()">
  <i class="pi pi-cog"></i>
</button>
<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-20rem">
  <!-- @if (_ws.socketStatus) {
    <small (click)="showIdSocket()">Server online <img src="assets/images/icons/square_green.svg" alt="" width="10px" height="10px"/></small>
  }
  @if (!_ws.socketStatus) {
    <small (click)="showIdSocket()">Server offline <img src="assets/images/icons/square_red.svg" alt="" width="10px" height="10px"/></small>
  } -->
  <!-- <p>@if (showId) {
    <small>Socket ID: {{ _ws.idSocket }}</small>
  }</p> -->
  <h5>Escala</h5>
  <div class="flex align-items-center">
    <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
    <div class="flex gap-2 align-items-center">
      @for (s of scales; track s) {
        <i class="pi pi-circle-fill text-300" [ngClass]="{'text-primary-500': s === scale}"></i>
      }
    </div>
    <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
  </div>
  @if (!minimal) {
    <h5>Ordenamiento de listados</h5>
    <div class="flex">
      <div class="field-radiobutton flex-1">
        <p-radioButton name="inputOrden" value="ASC" [(ngModel)]="inputOrden" inputId="asc_input"></p-radioButton>
          <label for="asc_input">Asc.</label>
        </div>
        <div class="field-radiobutton flex-1">
          <p-radioButton name="inputOrden" value="DESC" [(ngModel)]="inputOrden" inputId="desc_input"></p-radioButton>
            <label for="desc_input">Desc.</label>
          </div>
        </div>
        <h5>Iniciar en último año </h5>
        <p-inputSwitch [(ngModel)]="lastYear"></p-inputSwitch>
        }
      </p-sidebar>
