import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { BreakpointService } from '@core/services/breakpoint.service';
import { ColumnsService } from '@core/services/columns.service';
import { WebsocketService } from '@core/services/websocket.service';
import { AuthService } from '@shared/auth/services/auth.service';
import { ChoizMessagesService } from '@shared/components/choiz-messages/choiz-messages.service';
import { CookieService } from 'ngx-cookie-service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ChoizMessagesComponent } from '@shared/components/choiz-messages/choiz-messages.component';
import { ToastModule } from 'primeng/toast';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgxUiLoaderModule, ToastModule, ChoizMessagesComponent],
  providers: [DialogService, MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private primengConfig = inject(PrimeNGConfig);
  private _columns = inject(ColumnsService);
  private _bs = inject(BreakpointService);
  //private _ws = inject(WebsocketService);
  private _cookie = inject(CookieService);
  private _auth = inject(AuthService);
  private router = inject(Router);
  private _cms = inject(ChoizMessagesService);


  customMarker: any;
  roleUser: any;

  ngOnInit() {
    this._columns.initColumns().then(resp => {

    });
    this._bs.initBreakpointObserver();
    this.primengConfig.ripple = true;
    //
    const authUser = this._auth.getCurrentUser();
    this.roleUser = authUser.role;
    //this.sendPosition();
    //this.sendBrowser(navigator);
    // this._ws.listen('user').subscribe((idUser: any) => {
    //     console.log('User ->', idUser);
    //     this._ws.idSocket = idUser;
    // })
    // this._ws.listen('get-browser').subscribe((_navigator: any) => {
    //     this.sendBrowser(navigator)
    // })
    // switch(this.roleUser[0]){
    //     case 'ROLE_ADMIN':
    //         this._ws.listen('mensaje-admin').subscribe((mensaje: any) => {
    //             this._ws.mensaje = mensaje;
    //             this._cms.showMessage({ type: mensaje.type, message: mensaje.message, title: mensaje.from, whatsapp: false, autoClose: mensaje.autoClose, life: mensaje.life});
    //         })
    //         break;
    //     case 'ROLE_PHARMACY':
    //         this._ws.listen('mensaje-pharmacy').subscribe((mensaje: any) => {
    //             this._ws.mensaje = mensaje;
    //             this._cms.showMessage({ type: mensaje.type, message: mensaje.message, title: mensaje.from, whatsapp: false, autoClose: mensaje.autoClose, life: mensaje.life});
    //         })
    //         break;
    //     case 'ROLE_DOCTOR':
    //         this._ws.listen('mensaje-doctor').subscribe((mensaje: any) => {
    //             this._ws.mensaje = mensaje;
    //             this._cms.showMessage({ type: mensaje.type, message: mensaje.message, title: mensaje.from, whatsapp: false, autoClose: mensaje.autoClose, life: mensaje.life});
    //         })
    //         break;
    // }
    // this._ws.listen('mensaje-general').subscribe((mensaje: any) => {
    //     this._ws.mensaje = mensaje;
    //     this._cms.showMessage({ type: mensaje.type, message: mensaje.message, title: mensaje.from, whatsapp: false, autoClose: mensaje.autoClose, life: mensaje.life});
    // })
    // this._ws.listen('tasks').subscribe((task: any) => {
    //     if(task.task == 'LOGOUT'){
    //         this._auth.logout();
    //         this.router.navigateByUrl('/auth/entrar')
    //     }
    //     if(task.task == 'LOGIN'){
    //         this._auth.relogin(task.marcador.user.token, task.marcador.user);
    //     }
    // })
    setTimeout(() => {
        //this.emitirPosicion();
    }, 2000);
    window.addEventListener('beforeunload', (e) => {
        //this._ws.emit( 'mensaje-nuevo', 'Saliendo' );
    });
}

sendPosition(){
    if (navigator.geolocation) {
        const opciones = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 1000
        };
        const authUser = this._auth.getCurrentUser();
        if(authUser){
            //navigator.geolocation.getCurrentPosition(this.enviarPosicion, this.mostrarErrores, opciones);
            navigator.geolocation.getCurrentPosition((position) => {
                this.customMarker = {
                    id: new Date().toISOString(),
                    lng: position.coords.longitude,
                    lat: position.coords.latitude,
                    user: this._auth.getCurrentUser(),
                    nombre: this._auth.user.alias,
                    ip: this._cookie.get('ip'),
                    color: '#' + Math.floor(Math.random()*16777215).toString(16)
                }
            }, this.mostrarErrores, opciones);
        }
    }
}

sendBrowser(nav: any){
    //this._ws.emit( 'browser', nav );
}

emitirPosicion(){
    //this._ws.emit( 'marcador-nuevo', this.customMarker );
}

mostrarErrores(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            console.log('Permiso denegado por el usuario');
            break;
        case error.POSITION_UNAVAILABLE:
            console.log('Posición no disponible');
            break;
        case error.TIMEOUT:
            console.log('Tiempo de espera agotado');
            break;
        default:
            console.log('Error de Geolocalización desconocido :' + error.code);
    }
}
}
