<div class="menu-container">
  <div class="logo-container" style="height: 45px;">
    <img class="logo" width="40" height="40" src="../assets/images/favicon.ico" alt="Logo Choiz"/>
  </div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      @for (item of model; track item; let i = $index) {
        @if (!item.separator) {
          <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
        }
        @if (item.separator) {
          <li class="menu-separator"></li>
        }
      }
    </ul>
    <ul class="layout-menu">
      @for (item of model2; track item; let i = $index) {
        @if (!item.separator) {
          <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
        }
        @if (item.separator) {
          <li class="menu-separator"></li>
        }
      }
    </ul>
  </div>
</div>