<div class="layout-wrapper" [ngClass]="containerClass">
  @if (!_bs.isDesktop) {
    <app-topbar></app-topbar>
  }
  <div class="layout-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container" id="layout-main-container">
    <div class="layout-main">
      <div class="breadcrum-container">
        <choiz-breadcrumb></choiz-breadcrumb>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-config></app-config>
  <div class="layout-mask"></div>
</div>